import React, {useEffect, useState} from "react";
import {Navigate, useSearchParams} from "react-router-dom";
import {useCookies} from "react-cookie";
import {useRecoilState} from "recoil";

import Oferta from "../components/Oferta";
import Loading from "../components/utils/Loading";
import {processoState} from "../atoms/processAtom";
import {pushEventToDataLayer} from "../utils/gtm";
import {hash256} from "../utils/hashing";
import {indicacaoPlano, processoIniciado} from "../lib/api/ofertas";
import PreencheAniRentabilizacao from "../components/PreencheAniRentabilizacao";
import PreencheAniAquisicao from "../components/PreencheAniAquisicao";
import {validaTelefone} from "../utils/textUtils";
import {dadosOfertaeState} from "../atoms/dadosOfertaAtom";

export default function Identificacao() {
  const [urlParams] = useSearchParams();
  const pid = urlParams.get("pid");
  let ani = urlParams.get("ani");
  const nomeCliente = urlParams.get("nome");
  const origem = urlParams.get("utm_source") || "portal";
  const campanha = urlParams.get("utm_campaign");
  const medium = urlParams.get("utm_medium");
  const servico = urlParams.get("servico");
  const fluxo =
    urlParams.get("utm_campaign") === "aquisicao"
      ? "aquisicao"
      : "rentabilizacao";
  const direcao = "outbound";
  const codigoPlanoAtual = urlParams.get("codigoPlanoAtual");

  const [cookies, setCookie] = useCookies([
    "processo",
    "processId",
    "telefoneCliente",
  ]);
  const [processo, setProcesso] = useRecoilState(processoState);
  const [dadosOferta, setDadosOferta] = useRecoilState(dadosOfertaeState);
  const [processoApi, setProcessoApi] = useState(null);
  const [loadingProcesso, setLoadingProcesso] = useState(false);

  const [loadingOfertas, setLoadingOfertas] = useState(false);
  const [aniDigitado, setAniDigitado] = useState(null);

  const [erroProcess, setErro] = useState(null);
  const [ofertas, setOfertas] = useState(null);
  const [preencheAniRentabilizacao, setPreencheAniRentabilizacao] =
    useState(null);
  const [preencheAniAquisicao, setPreencheAniAquisicao] = useState(null);

  useEffect(() => {
    console.debug("dadosOferta", dadosOferta);
    console.debug("processoApi", processoApi);
    console.debug("preencheAniAquisicao", preencheAniAquisicao);
    console.debug("preencheAniRentabilizacao", preencheAniRentabilizacao);

    let erro = null;
    if (processoApi && !dadosOferta) {
      if (processoApi?.status === "processado") {
        erro = "PID já processado.";
        setErro({
          status: "iniciado",
          msg: "Se já fez um pedido através desse link, não é possível submeter novamente e você precisa aguardar a notificação de confirmação.",
        });
      }
      if (!processoApi?.nomeCliente && !processoApi?.telefoneCliente) {
        erro = "Sem ofertas disponíveis.";
        setErro({
          status: "sem-ofertas",
          msg: "Não encontramos ofertas disponíveis para esse número. Por favor aguarde um contato de um Consultor ou entre em contato ligando *144",
        });
      }

      if (processoApi?.message) {
        erro = "Erro request camunda.";

        setErro({
          status: "error",
          msg: "Não foi possível carregar as ofertas agora. Por favor, tente novamente mais tarde.",
        });
      }
      if (erro) {
        pushEventToDataLayer({
          event: "ofertasListaNaoVisualizar",
          erro,
          phone: processoApi?.telefoneCliente
            ? hash256(processoApi?.telefoneCliente)
            : "",
          userId: pid,
        });
        return;
      }

      if (fluxo === "aquisicao") {
        setProcesso({...processoApi, processId: pid});

        return;
      }
      setCookie("nomecliente", processoApi.nomeCliente, {
        path: "/",
        maxAge: process.env.REACT_APP_TEMPO_MAX_COOKIE || 172800,
      });
      setCookie("telefoneCliente", processoApi.telefoneCliente, {
        path: "/",
        maxAge: process.env.REACT_APP_TEMPO_MAX_COOKIE || 172800,
      });
      if (processoApi?.ofertas?.planos?.length > 0) {
        setOfertas(processoApi.ofertas?.planos);
        setProcesso({
          ...processoApi,
          processId: pid,
          ofertas: {planos: processoApi.ofertas.planos[0]},
        });
      } else {
        setProcesso({...processoApi, processId: pid});
      }

      return;
    }

    if (dadosOferta) {
      if (dadosOferta.code === "000404") {
        erro = "Sem ofertas disponíveis.";
        setErro({
          status: "sem-ofertas",
          msg: "Não encontramos ofertas disponíveis para esse número. Por favor aguarde um contato de um Consultor ou entre em contato ligando *144",
        });
      } else if (dadosOferta.code === "000208") {
        erro = "Sem ofertas disponíveis.";
        setErro({
          status: "sem-ofertas",
          msg: "Se já fez um pedido através desse link, não é possível fazer outro para o mesmo número. Você precisa aguardar a notificação de confirmação.",
        });
      } else if (dadosOferta.message) {
        erro = "Erro API.";

        setErro({
          status: "error",
          msg: "Não foi possível carregar as ofertas agora. Por favor, tente novamente mais tarde.",
        });
      }
      if (erro) {
        pushEventToDataLayer({
          event: "ofertasListaNaoVisualizar",
          erro,
          phone: ani ? hash256(ani) : "",
          //userId: paramPid,
        });
        return;
      }

      setOfertas(dadosOferta.ofertas);
      setProcesso({
        ...processo,
        origem,
        dadosOferta: true,
        nomeCliente: nomeCliente || processoApi?.nomeCliente,
        //telefoneCliente: aniDigitado,//TODO REVER
        ofertas: {planos: dadosOferta.ofertas},
        tipo_processo: campanha,
      });
      setCookie("nomecliente", nomeCliente, {
        path: "/",
        maxAge: process.env.REACT_APP_TEMPO_MAX_COOKIE || 172800,
      });
      setCookie("telefoneCliente", aniDigitado, {
        path: "/",
        maxAge: process.env.REACT_APP_TEMPO_MAX_COOKIE || 172800,
      });
      return;
    }
    setProcesso(null);
    if (!loadingOfertas && !loadingProcesso) {
      setErro({
        status: "noProcess",
        msg: "Não encontramos ofertas disponíveis para esse número. Por favor aguarde um contato de um Consultor ou entre em contato ligando *144",
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [processoApi, dadosOferta]);

  useEffect(() => {
    if (pid) {
      pushEventToDataLayer({
        event: "dimensoes_personalizadas",
        userId: pid,
        ep_telefone: hash256(cookies?.telefoneCliente),
      });

      const fetch = async () => {
        setLoadingProcesso(true);
        const data = await processoIniciado(`/oferta/pedido/${pid}`);
        setProcessoApi(data);
        setLoadingProcesso(false);
      };
      fetch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pid]);

  const fetchDadosOferta = async (
    ani,
    operadora,
    ddd,
    numeroTemporario,
    metadata,
  ) => {
    setLoadingOfertas(true);

    let filtroPrioritario = undefined;
    if (
      process.env.REACT_APP_SOURCES_FILTRO_PRIORITARIO?.split(",").includes(
        origem,
      )
    ) {
      filtroPrioritario = origem;
    }
    const express = medium === "express";
    const segmentoAtual = campanha.includes("_")
      ? campanha.split("_")[0]
      : campanha;
    const data = await indicacaoPlano({
      url: `/ofertas/indica-plano`,
      args: {
        ani,
        origem,
        fluxo,
        direcao,
        operadora,
        ddd,
        filtroPrioritario,
        express,
        segmentoAtual,
        codigoPlanoAtual,
      },
    });

    if (!data) {
      setErro({
        status: "error",
        msg: "Não foi possível carregar as ofertas agora. Por favor, tente novamente mais tarde.",
      });
      setLoadingOfertas(false);

      return;
    }
    setProcesso({
      ...processo,
      processId: pid,
      origem,
      fluxo,
      operadora,
      ddd,
      dadosOferta: true,
      nomeCliente,
      telefoneCliente: ani,
      telefoneEntrada: ani,
      tipo_processo: campanha,
      numeroTemporario,
      ...metadata,
      identificacao: true,
    });
    setDadosOferta(data);
    setLoadingOfertas(false);
  };

  useEffect(() => {
    console.debug("validaTelefone", ani, validaTelefone(ani));
    if (!validaTelefone(ani)) {
      ani = "";
    }

    if (campanha === "aquisicao") {
      setErro(null);
      pushEventToDataLayer({
        event: "view_desambiguador",
      });
      setPreencheAniAquisicao(true);
      return;
    }
    if (origem && campanha && !ani) {
      setErro(null);
      setPreencheAniRentabilizacao(true);
      return;
    }

    if (origem && campanha && ani) {
      setErro(null);
      setAniDigitado(ani);
      fetchDadosOferta(ani);
      return;
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [origem, ani]);

  const handleAniDigitado = (aniDigitado) => {
    setErro(null);
    setAniDigitado(aniDigitado);
    fetchDadosOferta(aniDigitado);
    setPreencheAniRentabilizacao(false);
  };

  const handleAquisicaoPreenchida = (objeto) => {
    setErro(null);
    console.debug("aquisicao", objeto);

    if (objeto.servico === "portabilidade") {
      pushEventToDataLayer({
        event: "desambiguador_portabilidade",
        operadora_atual: objeto.operadora,
      });

      setAniDigitado(objeto.ani);
      setCookie("telefoneCliente", objeto.ani);

      fetchDadosOferta(
        objeto.ani,
        objeto.operadora,
        null,
        objeto.numeroTemporario,
        {
          servicoAquisicao: "portabilidade",
          desambiguador: "desambiguador_portabilidade",
        },
      );
    }

    if (objeto.servico === "nova-linha") {
      pushEventToDataLayer({
        event: "desambiguador_nova_linha",
        estado: objeto.estado,
      });

      setAniDigitado("");
      ani = "";
      setCookie("telefoneCliente", "");
      fetchDadosOferta(null, objeto.operadora, objeto.ddd, null, {
        servicoAquisicao: "nova-linha",
        desambiguador: "desambiguador_nova_linha",
      });
    }

    setPreencheAniAquisicao(false);
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  console.debug("erroProcess", erroProcess);

  if (ofertas?.length > 0) {
    pushEventToDataLayer({
      event: "dimensoes_personalizadas",
      userId: "",
      ep_email: "",
      ep_telefone: hash256(processo?.telefoneCliente),
      fluxo: processo?.fluxo,
      desambiguador: processo?.desambiguador,
    });

    return <Navigate replace to="/ofertas" />;
  }

  return (
    <>
      <Oferta exibePlano={!erroProcess} />
      <div className="flex flex-col h-min">
        <div className="flex justify-center px-6 py-8 bg-white md:px-48">
          <Loading
            text=""
            show={loadingOfertas || loadingProcesso}
            speed={120}
            className="w-8/12 mx-auto "
          />

          {(erroProcess?.status === "noProcess" ||
            erroProcess?.status === "sem-ofertas" ||
            erroProcess?.status === "iniciado") && (
            <p className="font-medium text-secondary-tim">{erroProcess.msg}</p>
          )}

          {erroProcess?.status === "error" && (
            <p className="font-medium text-red-400">{erroProcess.msg}</p>
          )}
          {!erroProcess && !loadingOfertas && !dadosOferta?.ofertas?.length && (
            <p className="font-medium text-secondary-tim">
              Não encontramos ofertas disponíveis para esse número. Por favor
              aguarde um contato de um Consultor ou entre em contato ligando
              *144
            </p>
          )}
        </div>
      </div>
      {preencheAniRentabilizacao === true && (
        <div>
          <PreencheAniRentabilizacao callback={handleAniDigitado} />
        </div>
      )}

      {preencheAniAquisicao === true && (
        <div>
          <PreencheAniAquisicao
            ani={ani}
            callback={handleAquisicaoPreenchida}
            medium={medium}
            origem={origem}
            novaLinha={medium === "nova-linha" || servico === "nova-linha"}
            portabilidade={
              medium === "portabilidade" || servico === "portabilidade"
            }
          />
        </div>
      )}
    </>
  );
}
