const server = process.env.REACT_APP_ENDPOINT_API;

export async function consultaPlano(ani) {
  if (!ani) {
    return;
  }
  const response = await fetch(server + "/tipo-plano-cliente/" + ani, {
    headers: {
      "content-type": "application/json",
      "x-api-key": process.env.REACT_APP_API_KEY_PORTAL,
    },
  }).catch(() => {
    return null;
  });

  if (response?.body) {
    return await response.json().catch(() => {
      return null;
    });
  }

  return null;
}
