import React from "react";
import Img from "./imageApps/Img";

export default function BeneficiosExtensao({oferta}) {
  let streamings = oferta?.objBeneficios.filter(
    (b) => b?.tipo === "streamingsInclusos",
  );
  const apps = streamings.map((b) => b.ItensBeneficioExtensao)?.[0];

  return (
    <>
      {!oferta || !apps?.length ? (
        <></>
      ) : (
        <div className="flex flex-row space-x-2 text-secondary-tim">
          {apps?.length > 0 && (
            <>
              <div className="flex flex-col py-2 ">
                <h5 className="font-bold text-xs text-primary-tim-light">
                  Uma assinatura mensal inclusa entre:
                </h5>
                <div className="flex flex-wrap">
                  {apps.map((app) => {
                    console.log("app", app.id);
                    return (
                      <Img
                        key={app.id}
                        app={app.col2}
                        nomeImagem={app.col3}
                        height="h-7"
                      />
                    );
                  })}
                </div>
              </div>
            </>
          )}
        </div>
      )}
    </>
  );
}
