function getMatriz(dataContrato) {
  const vencimento = [1, 10, 12, 15, 20];
  let dataRef = new Date(dataContrato.getTime());
  const diaContratoM1 = new Date(dataRef.setDate(dataRef.getDate() + 28));
  dataRef = new Date(dataContrato.getTime());
  const diaContratoM2 = new Date(dataRef.setDate(dataRef.getDate() + 33));

  const i8 = [
    vencimento[0] - diaContratoM1.getDate(),
    vencimento[1] - diaContratoM1.getDate(),
    vencimento[2] - diaContratoM1.getDate(),
    vencimento[3] - diaContratoM1.getDate(),
    vencimento[4] - diaContratoM1.getDate(),
    vencimento[5] - diaContratoM1.getDate(),
  ];

  const j8 = [
    vencimento[0] - diaContratoM2.getDate(),
    vencimento[1] - diaContratoM2.getDate(),
    vencimento[2] - diaContratoM2.getDate(),
    vencimento[3] - diaContratoM2.getDate(),
    vencimento[4] - diaContratoM2.getDate(),
    vencimento[5] - diaContratoM2.getDate(),
  ];
  const dados = [
    [i8[0], j8[0], vencimento[0]],
    [i8[1], j8[1], vencimento[1]],
    [i8[2], j8[2], vencimento[2]],
    [i8[3], j8[3], vencimento[3]],
    [i8[4], j8[4], vencimento[4]],
    [i8[5], j8[5], vencimento[5]],
  ];

  return {i8, j8, dados};
}
export function obterData(dataContrato) {
  const {dados} = getMatriz(dataContrato);

  const menorI = Math.min(
    ...dados.filter((row) => row[0] >= 0).map((row) => row[0]),
  );
  const index = dados.findIndex((row) => row[0] === menorI);

  const data2 =
    index >= 0
      ? new Date(
          dataContrato.getFullYear(),
          dataContrato.getMonth() + 1,
          dados[index][2],
        )
      : null;

  const diaMais40 = dataContrato.getDate() + 40;
  let data3 = new Date(dataContrato.getTime()).setDate(diaMais40);
  data3 = new Date(new Date(data3).setDate(1));

  return index === -1 ? data3 : data2;
}
