import React, {useState} from "react";
import {
  ArrowSmallLeftIcon,
  CheckIcon,
  PlusSmallIcon,
  StarIcon,
} from "@heroicons/react/24/outline";
import {StarIcon as StarSolidIcon} from "@heroicons/react/24/solid";
import {ExclamationCircleIcon} from "@heroicons/react/24/solid";

import ImageApps from "../utils/imageApps/ImageApps";
import {
  getCentavosValor,
  getDezenasValor,
  getMedidaDados,
  getNumDados,
} from "../../utils/separacaoTexto";
import Beneficios from "../Beneficios";
import {useRecoilState} from "recoil";
import {
  passoCarrinhoState,
  ultimoPassoCarrinhoSelector,
} from "../../atoms/passoCarrinhoAtom";
import Img from "../utils/imageApps/Img";
import BonusInternet from "../utils/BonusInternet";
import ImgLimitado3m from "../utils/imageApps/ImgLimitado3m";
import BeneficiosExtensao from "../utils/BeneficiosExtensao";

export default function PlanoSelecionado({
  planoSelecionado,
  processo,
  handleVoltarPasso,
  tagEventPayload,
}) {
  const [showInfoPlano, setShowInfoPlano] = useState(false);
  const [showBeneficios, setShowBeneficios] = useState(false);
  const [passoCarrinho] = useRecoilState(passoCarrinhoState);
  const [ultimoPassoCarrinho] = useRecoilState(ultimoPassoCarrinhoSelector);
  const valorExibido =
    planoSelecionado?.fidelizado || processo?.fluxo === "rentabilizacao"
      ? planoSelecionado?.valorFinal
      : planoSelecionado?.valor;

  const handleShowBeneficios = () => {
    setShowBeneficios(true);
  };

  return (
    <div
      className={`w-full ${
        !ultimoPassoCarrinho
          ? "bg-cinza-base"
          : "bg-verde-tim-dark bg-opacity-30"
      }`}
    >
      <div
        className={`flex flex-col p-4 md:mx-auto md:px-8 text-secondary-tim controle-tamanho-tela `}
      >
        <div className="flex flex-row items-center space-x-3 md:mt-4">
          {!ultimoPassoCarrinho && (
            <ArrowSmallLeftIcon
              onClick={handleVoltarPasso}
              className="mt-1 cursor-pointer w-7"
            />
          )}
          {ultimoPassoCarrinho && (
            <CheckIcon className="mt-1 font-bold cursor-pointer w-7 text-verde-tim-dark" />
          )}
          <h3 className="text-sm font-light ">
            {processo?.nomeCliente
              ? processo?.nomeCliente.split(" ")[0] + ", você"
              : "Você"}
            {ultimoPassoCarrinho ? " contratou" : " está contratando"}:
          </h3>
        </div>
        <div className="flex flex-col justify-between md:flex-unset lg:justify-center xl:space-x-10 md:flex-wrap">
          <div className="flex flex-col mt-2 pl-9 md:pl-0">
            <div className="flex flex-col">
              <h4 className="relative">
                <span className="text-xl sm:text-2xl font-semibold leading-[.65rem]">
                  {planoSelecionado?.planoDestinoExibicao}
                </span>

                {passoCarrinho < 4 && planoSelecionado?.beneficiosC6 && (
                  <>
                    <ExclamationCircleIcon
                      onClick={() => setShowInfoPlano(!showInfoPlano)}
                      className="inline w-6 ml-2 -mt-1 cursor-pointer"
                    />
                    {showInfoPlano && (
                      <span className="absolute block p-2 text-xs bg-white rounded-md shadow-lg shadow- shadow-gray-600 -bottom-30 text-cinza-neutro">
                        {planoSelecionado.beneficiosC6}
                      </span>
                    )}
                  </>
                )}
              </h4>
            </div>
            <p className="flex flex-row items-end space-x-4 py-2 sm:space-x-8">
              <strong className="flex flex-col  font-normal mx-1 text-lg">
                <span className="text-sm">Até</span>
                <span className={` font-extrabold text-5xl sm:text-7xl`}>
                  {getNumDados(planoSelecionado?.dadosTotal)}
                  <span className={` text-3xl sm:text-4xl`}>
                    {getMedidaDados(planoSelecionado?.dadosTotal)}
                  </span>
                </span>
              </strong>
              <p className="font-semibold text-lg">
                <span className="flex flex-row items-center">
                  <span className="block mr-1 text-sm font-medium sm:text-xl">
                    R$
                  </span>
                  <span className="text-5xl font-extrabold sm:text-7xl">
                    {getDezenasValor(valorExibido)}
                  </span>
                  <span className="flex flex-col ">
                    <span className="text-base font-extrabold sm:text-xl">
                      ,{getCentavosValor(valorExibido)}
                    </span>
                    <span className="-mt-1 text-sm font-medium sm:text-xl">
                      /mes
                    </span>
                  </span>
                </span>
              </p>
            </p>
            <div className="flex flex-col space-y-2 py-1">
              <div>
                {/* <h5 className="font-light text-gray-700 text-sm ">Bônus</h5> */}
                <BonusInternet
                  textColor="text-secondary-tim"
                  composicao={true}
                  oferta={planoSelecionado}
                />
              </div>

              {/* <div>
                <h5 className="font-light text-gray-700 text-sm ">Benefícios</h5>
                <ListaBeneficios beneficios={planoSelecionado?.objBeneficios} />
                </div> */}
              <p className="text-sm font-normal leading-3 px-4 py-1 bg-gray-300 text-gray-700 rounded-full inline max-w-fit">
                {planoSelecionado?.fidelizado === true
                  ? "Plano fidelizado"
                  : "Plano não fidelizado"}
              </p>
            </div>
          </div>

          <div className="flex flex-row items-center pl-9 md:pl-0 xl:px-9 justify-between ">
            <div className="flex flex-col py-2 ">
              {planoSelecionado?.deezer && (
                <div className="mb-0">
                  <Img height="h-9" app={"Deezer"} />
                </div>
              )}
              <div className="">
                {planoSelecionado && (
                  <BeneficiosExtensao oferta={planoSelecionado} />
                )}
              </div>
              <div className="flex flex-col gap-1">
                <h5 className="block font-bold text-xs text-primary-tim-light">
                  Sem descontar da franquia:
                </h5>
                <div className="flex flex-col">
                  <ImageApps oferta={planoSelecionado} />
                </div>
              </div>
              {planoSelecionado?._id ===
                "7390821b-0797-490b-966b-798dbadc1785" && (
                <div>
                  <ImgLimitado3m />
                </div>
              )}
            </div>
          </div>
        </div>
        <div
          onClick={handleShowBeneficios}
          className="flex flex-row items-center justify-between p-4 mt-2 font-bold bg-white shadow-lg cursor-pointer bg-opacity-30 hover:shadow-md text-primary-tim-light"
        >
          <div className="flex flex-row items-center space-x-2">
            <StarIcon className="w-6 text-verde-tim" />
            <span className="text-xs">Mais beneficios</span>
          </div>
          <PlusSmallIcon className="w-6" />
        </div>
      </div>
      <Beneficios
        oferta={planoSelecionado}
        exibir={showBeneficios}
        onClose={() => setShowBeneficios(false)}
      />
    </div>
  );
}

const ListaBeneficios = ({beneficios}) => {
  if (!beneficios) {
    return null;
  }

  return (
    <div className="flex flex-col text-sm sm:text-base sm:flex-row sm:space-x-2 sm:items-center ">
      {beneficios.map((beneficio, index) =>
        beneficio.tipo !== "beneficios" ? null : (
          <span>
            <StarSolidIcon className="w-3 inline-block mb-1 mr-1" />
            <span key={"b_lista_" + index}>
              {beneficio.item.split("|")?.[0]}
            </span>
          </span>
        ),
      )}
    </div>
  );
};
