import {ArrowPathIcon, PhoneIcon} from "@heroicons/react/24/solid";
import React from "react";
import {useState} from "react";

import {formataTelefone, validaTelefone} from "../utils/textUtils";
import {consultaPlano} from "../lib/api/clientes";
import {PlusIcon} from "@heroicons/react/24/outline";

function PreencheAniConsultaPlano({callback}) {
  const [ani, setAni] = useState("");
  const [tipo, setTipo] = useState("");
  const [loading, setLoading] = useState(false);
  const [selecionaPlano, setSelecionaPlano] = useState(false);
  const [erro, setErro] = useState(null);

  const handleContinuar = () => {
    setErro(null);
    if (!validaTelefone(ani)) {
      setErro("Informe um número de celular válido.");
      return;
    }
    if (!tipo) {
      setErro("Selecione o seu plano atual.");
      return;
    }
    callback({
      ani: ani.replace(/([^0-9])/gi, ""),
      tipo,
      ativo: true,
      code: "",
    });
  };

  const consultarPlano = async () => {
    setErro(null);

    if (!validaTelefone(ani)) {
      setErro("Informe um número de celular válido.");
      return;
    }
    setLoading(true);
    const data = await consultaPlano(ani.replace(/([^0-9])/gi, ""));
    console.log(data);
    setLoading(false);

    if (!data?.tipo) {
      setSelecionaPlano(true);
      return;
    }

    callback({ani: ani.replace(/([^0-9])/gi, ""), ...data});
  };

  return (
    <div className="fixed z-50 w-full h-screen bg-black bg-opacity-70 top-0">
      <div className="flex h-full flex-col items-center justify-center">
        <div className="mx-8 max-w-sm relative bg-white text-gray-800 rounded-lg p-8 px-12">
          <div className="flex items-center pb-2 absolute right-4 top-3 justify-end">
            <PlusIcon
              onClick={() => callback({close: true})}
              className="h-6 w-6 hover:cursor-pointer rotate-45 text-secondary-tim"
            />
          </div>
          <h3 className="text-lg font-light py-4 leading-tight">
            Informe seu número TIM
          </h3>
          <div className="flex flex-row justify-between w-full relatite">
            <input
              name="ani"
              type="text"
              required
              value={ani}
              placeholder="(  )      -"
              onBlur={(e) => setAni(formataTelefone(e.target.value))}
              onChange={(e) => setAni(e.target.value)}
              className={`w-full border-b border-secondary-tim outline-none text-gray-600 font-medium `}
            />
            <PhoneIcon className="icon-input" />
          </div>
          {erro && (
            <p className="text-sm font-medium text-red-400 leading-snug py-4">
              {erro}
            </p>
          )}

          {!selecionaPlano ? (
            <button
              type="button"
              onClick={consultarPlano}
              disabled={loading}
              className={`flex flex-row justify-center items-center mt-4 text-center px-8 w-full py-3 text-xs font-extrabold text-white uppercase rounded-full bg-secondary-tim disabled:bg-secondary-tim/70`}
            >
              {loading ? (
                <ArrowPathIcon className="inline w-6 h-6 ml-2 icon-input animate-spin text-white" />
              ) : (
                "Continuar"
              )}
            </button>
          ) : (
            <div className="flex flex-col gap-2 py-4">
              <label className="text-sm font-semibold">
                Selecione seu plano atual
              </label>
              <div className="flex items-center">
                <input
                  type="radio"
                  id="pre"
                  name="plano"
                  checked={tipo === "pre"}
                  onChange={(e) => setTipo(e.target.id)}
                  className="w-5 h-5 mr-2 text-blue-600 bg-gray-100 border-gray-300 focus:ring-secondary-tim-light checked:bg"
                />
                <label
                  htmlFor="pre"
                  className="w-full text-sm font-medium text-gray-700"
                >
                  Pré-Pago
                </label>
              </div>
              <div className="flex items-center">
                <input
                  type="radio"
                  id="controle"
                  name="plano"
                  checked={tipo === "controle"}
                  onChange={(e) => setTipo(e.target.id)}
                  className="w-5 h-5 mr-2 text-blue-600 bg-gray-100 border-gray-300 focus:ring-secondary-tim-light checked:bg"
                />
                <label
                  htmlFor="controle"
                  className="w-full text-sm font-medium text-gray-700"
                >
                  Controle
                </label>
              </div>
              <div className="flex items-center">
                <input
                  type="radio"
                  id="pos"
                  name="plano"
                  checked={tipo === "pos"}
                  onChange={(e) => setTipo(e.target.id)}
                  className="w-5 h-5 mr-2 text-blue-600 bg-gray-100 border-gray-300 focus:ring-secondary-tim-light checked:bg"
                />
                <label
                  htmlFor="pos"
                  className="w-full text-sm font-medium text-gray-700"
                >
                  Pós-Pago
                </label>
              </div>
              <button
                type="button"
                onClick={handleContinuar}
                disabled={loading}
                className={`flex flex-row justify-center items-center mt-4 text-center px-8 w-full py-3 text-xs font-extrabold text-white uppercase rounded-full bg-secondary-tim disabled:bg-secondary-tim/70`}
              >
                Continuar
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default PreencheAniConsultaPlano;
