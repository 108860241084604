import {EnvelopeIcon, ShoppingBagIcon} from "@heroicons/react/24/outline";
import React, {useEffect, useState} from "react";
import {useRecoilState} from "recoil";
import {processoState} from "../../atoms/processAtom";
import ResumoPedido from "../ResumoPedido";
import {planoSelecionadoState} from "../../atoms/planoSelecionadoAtom";
import {dadosClienteState} from "../../atoms/dadosClienteAtom";

export default function Finalizacao() {
  const [processo] = useRecoilState(processoState);
  const [planoSelecionado] = useRecoilState(planoSelecionadoState);
  const [dadosCliente] = useRecoilState(dadosClienteState);
  const [exibeResumo, setExibeResumo] = useState(false);
  return (
    <>
      <div className="py-2 bg-white text-secondary-tim px-4 md:px-14 controle-tamanho-tela">
        <h3
          className="cursor-pointer text-xs font-bold text-left w-full py-2 px-2 block"
          onClick={() => setExibeResumo(!exibeResumo)}
        >
          <span>
            {!exibeResumo ? "Exibir resumo do Pedido " : "Esconder resumo "}
            <ShoppingBagIcon className="w-4 h-4 inline" />
          </span>
        </h3>
        <div className={`${exibeResumo ? "block" : "hidden"}`}>
          <ResumoPedido
            planoSelecionado={planoSelecionado}
            dadosCliente={dadosCliente}
          />
        </div>
      </div>
      <div className="w-full bg-base-tim-dark">
        <div className="flex flex-col px-4 py-5 space-y-3 text-white md:px-14 controle-tamanho-tela">
          <p className="flex flex-row items-center space-x-2 text-xs">
            <span className="w-9">
              <EnvelopeIcon className="w-9" />
            </span>
            {processo.tipo_processo === "aquisicao" && (
              <span className="text-sm">
                Em breve você receberá um link para rastreamento da entrega.
                Serão efetuadas 3 tentativas de entrega. Se não for possível
                entregar, o pedido será cancelado.
              </span>
            )}
            {processo.tipo_processo !== "aquisicao" && (
              <span className="">
                Seu pedido será analisado e em breve você receberá uma mensagem
                de confirmação. Se aprovado, a migração de seu plano e a
                liberação de seus benefícios ocorrerão em até 24h, assim na sua
                próxima fatura será cobrado ainda pelo valor do plano antigo e
                somente na segunda fatura você receberá a cobrança do seu novo
                plano.
              </span>
            )}
          </p>
        </div>
      </div>
    </>
  );
}
