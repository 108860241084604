import {useState} from "react";
import PreencheAniConsultaPlano from "./PreencheAniConsultaPlano";
import {pushEventToDataLayer} from "../utils/gtm";
import {hash256} from "../utils/hashing";
import {useSearchParams} from "react-router-dom";
import {GoogleReCaptchaProvider} from "@google-recaptcha/react";

function Landing() {
  const [exibeConsultaPlano, setExibeConsultaPlano] = useState(false);
  const [urlParams] = useSearchParams();

  const origem = urlParams.get("utm_source");
  const medium = urlParams.get("utm_medium");

  const handleSelecaoPlano = ({ativo, tipo, ani, code, close}) => {
    if (close) {
      setExibeConsultaPlano(false);
      return;
    }

    if (!ativo) {
      pushEventToDataLayer({
        event: "ofertasListaNaoVisualizar",
        erro: "sem-ofertas",
        phone: ani ? hash256(ani) : "",
        //userId: paramPid,
      });

      setTimeout(() => {
        setExibeConsultaPlano(false);

        window.location.href = `/sem-ofertas`;
      }, 300);

      return;
    }

    setExibeConsultaPlano(false);

    if (tipo === "pre") {
      tipo = "pre_controle";
    }

    window.location.href = `/?utm_source=${origem || "web"}&utm_medium=${
      medium || "organico"
    }&utm_campaign=${tipo}&ani=${ani}&codigoPlanoAtual=${code || ""}`;
  };

  return (
    <GoogleReCaptchaProvider
      type="v2-invisible"
      siteKey="6LfvPNoqAAAAAEhI14jNQtz8R6l0u2SDrY9pnBbx"
    >
      <div className="flex flex-col justify-center text-white w-full min-h-screen gap-3 items-center bg-gradient-to-br from-[#002198] to-[#0096E7]">
        <div>
          <img
            id="topo-logo"
            src="/assets/tim-logo.png"
            alt="Logo TIM"
            className="h-5 my-4 text-white md:h-6 md:my-6"
          />
        </div>
        <div className="flex flex-col grow justify-center relative z-20 gap-3 items-center mx-6 px-6 py-6">
          <h1 className="text-xl">Encontre aqui a melhor oferta para você:</h1>
          <a
            href={`/?utm_source=${origem || "web"}&utm_medium=${
              medium || "organico"
            }&utm_campaign=aquisicao&servico=portabilidade`}
            className="block text-center bg-white w-full text-[#001D85] text-lg px-4 shadow-md shadow-[#001D85]/70 rounded-lg py-2"
          >
            Portabilidade para TIM
          </a>
          <a
            href={`/?utm_source=${origem || "web"}&utm_medium=${
              medium || "organico"
            }&utm_campaign=aquisicao&servico=nova-linha`}
            className="block text-center bg-white w-full text-[#001D85] text-lg px-4 shadow-md shadow-[#001D85]/70 rounded-lg py-2"
          >
            Nova linha
          </a>
          <button
            type="button"
            onClick={() => setExibeConsultaPlano(true)}
            className="bg-white w-full text-[#001D85] text-lg px-4 shadow-md shadow-[#001D85]/70 rounded-lg py-2"
          >
            Mudar plano atual
          </button>
        </div>
        <div className="flex w-full">
          <img
            id="topo-logo"
            src="/assets/institucional.png"
            alt="Logo TIM"
            className="w-full sm:max-w-xs mx-auto text-white "
          />
        </div>
      </div>
      {exibeConsultaPlano && (
        <PreencheAniConsultaPlano callback={handleSelecaoPlano} />
      )}
    </GoogleReCaptchaProvider>
  );
}

export default Landing;
