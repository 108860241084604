import {Analytics} from "@vercel/analytics/react";
import {useEffect} from "react";
import {withCookies} from "react-cookie";
import {Routes, Route, useSearchParams} from "react-router-dom";
import {useRecoilState} from "recoil";
import {exibirDuvidasState} from "./atoms/exibirDuvidasAtom";

import Duvidas from "./components/Duvidas";
import Topo from "./components/Topo";
import Carrinho from "./pages/Carrinho";
import Identificacao from "./pages/Identificacao";
import Planos from "./pages/Planos";
import Rodape from "./components/Rodape";
import FecharPedido from "./components/carrinho/FecharPedido";
import Landing from "./components/Landing";
import SemOfertas from "./components/SemOfertas";

function App() {
  const [exibirDuvidas, setExibirDuvidas] = useRecoilState(exibirDuvidasState);
  const [urlParams] = useSearchParams();
  const origem = urlParams.get("utm_source");
  const campanha = urlParams.get("utm_campaign");

  useEffect(() => {
    if (exibirDuvidas === 1) {
      document.querySelector(".div-duvidas").scrollIntoView({
        behavior: "smooth",
      });
      setExibirDuvidas(0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [exibirDuvidas]);

  if (!campanha && window.location.pathname === "/") {
    return <Landing />;
  }

  return (
    <div className="w-screen min-h-screen overflow-x-hidden bg-[#002198] pt-11 ">
      <Topo />
      <Routes>
        {origem && campanha && <Route path="/" element={<Identificacao />} />}
        <Route path="/carrinho/pedido/*" element={<FecharPedido />} />
        <Route path="/carrinho/*" element={<Carrinho />} />
        <Route path="/ofertas" element={<Planos />} />
        <Route path="/sem-ofertas" element={<SemOfertas />} />
      </Routes>
      {/* Duvidas */}
      {exibirDuvidas !== null && <Duvidas />}
      {/* Flutuante <WhatsApp /> */}
      <Rodape />
      <Analytics />
    </div>
  );
}

export default withCookies(App);
