import React from "react";

function SemOfertas() {
  return (
    <div className="flex flex-col justify-center items-center w-full mx-auto p-4 bg-white">
      <p className="max-w-lg py-2">
        Não encontramos ofertas disponíveis para esse número. Por favor aguarde
        um contato de um Consultor ou entre em contato ligando *144.
      </p>
    </div>
  );
}

export default SemOfertas;
