import React, {useEffect, useState} from "react";
import {useSearchParams} from "react-router-dom";

import {useCookies} from "react-cookie";
import {Navigate} from "react-router";
import {useRecoilState} from "recoil";
import {dadosClienteState} from "../atoms/dadosClienteAtom";
import {
  passoCarrinhoState,
  ultimoPassoCarrinhoSelector,
} from "../atoms/passoCarrinhoAtom";
import {planoSelecionadoState} from "../atoms/planoSelecionadoAtom";
import {processoState} from "../atoms/processAtom";

import DadosCliente from "../components/carrinho/DadosCliente";
import DadosContato from "../components/carrinho/DadosContato";
import DadosPagamento from "../components/carrinho/DadosPagamento";
import Finalizacao from "../components/carrinho/Finalizacao";
import PlanoSelecionado from "../components/carrinho/PlanoSelecionado";
import {pushEventToDataLayer} from "../utils/gtm";
import {hash256} from "../utils/hashing";
import {formataItemPrice, validaTelefone} from "../utils/textUtils";
import {ofertaPorId} from "../lib/api/ofertas";
import {dadosOfertaeState} from "../atoms/dadosOfertaAtom";
import {convertApiAntiga} from "../lib/migracao/deParaCamposOferta";
import Loading from "../components/utils/Loading";
import PreencheAniRentabilizacao from "../components/PreencheAniRentabilizacao";
import PreencheAniAquisicao from "../components/PreencheAniAquisicao";

export default function Carrinho() {
  const [processo, setProcesso] = useRecoilState(processoState);
  const [, setDadosOferta] = useRecoilState(dadosOfertaeState);
  const [passoCarrinho, setPassoCarrinho] = useRecoilState(passoCarrinhoState);
  const [ultimoPassoCarrinho] = useRecoilState(ultimoPassoCarrinhoSelector);
  const [planoSelecionado, setPlanoSelecionado] = useRecoilState(
    planoSelecionadoState,
  );
  const [dadosCliente] = useRecoilState(dadosClienteState);
  const [cookies, setCookie] = useCookies(["telefoneCliente"]);
  const [preencheAni, setPreencheAni] = useState(null);

  const [loading, setLoading] = useState(null);

  const [urlParams] = useSearchParams();
  const oferta = urlParams.get("oferta");
  const [ani, setAni] = useState(urlParams.get("ani"));

  const nomeCliente = urlParams.get("nome");
  const origem = urlParams.get("utm_source") || "portal";
  const campanha = urlParams.get("utm_campaign");
  const medium = urlParams.get("utm_medium");
  const fluxo =
    urlParams.get("utm_campaign") === "aquisicao"
      ? "aquisicao"
      : "rentabilizacao";

  const handleVoltarPasso = () => {
    if (passoCarrinho === 1) {
      setPlanoSelecionado(null);
    }
    setPassoCarrinho(passoCarrinho - 1);
  };
  const payloadEvent = (eventName) => {
    return {
      eventName,
      payload: {
        phone: hash256(processo?.telefoneCliente),
        // content_id: planoSelecionado?.id,
        value: formataItemPrice(planoSelecionado?.valorFinal),
        email: dadosCliente?.emailCliente
          ? hash256(dadosCliente?.emailCliente)
          : "",
      },
    };
  };

  const fetcherOferta = async (
    idOferta,
    ani,
    operadora,
    ddd,
    numeroTemporario,
    metadata,
  ) => {
    setLoading(true);

    console.debug("fetcherOferta", idOferta);
    let data = await ofertaPorId(idOferta);
    setProcesso({
      ...processo,
      origem,
      fluxo,
      operadora,
      ddd,
      dadosOferta: true,
      nomeCliente,
      telefoneCliente: ani,
      telefoneEntrada: ani,
      numeroTemporario,
      tipo_processo: campanha,
      ...metadata,
    });
    setDadosOferta([data]);

    data = convertApiAntiga([data] || []);
    setPlanoSelecionado(data?.[0]);
    setLoading(false);
    setPassoCarrinho(1);
  };

  const handleAniDigitado = (aniDigitado) => {
    setAni(aniDigitado);
    setPreencheAni(false);
    fetcherOferta(oferta, aniDigitado, null, null, null, {});
  };

  const handleAquisicaoPreenchida = (objeto) => {
    console.debug("handleAquisicaoPreenchida", objeto);
    console.debug("aquisicao", objeto);

    if (objeto.servico === "portabilidade") {
      pushEventToDataLayer({
        event: "desambiguador_portabilidade",
        operadora_atual: objeto.operadora,
      });

      setAni(objeto.ani);
      setCookie("telefoneCliente", objeto.ani);

      fetcherOferta(
        oferta,
        objeto.ani,
        objeto.operadora,
        null,
        objeto.numeroTemporario,
        {
          identificacao: true,
          servicoAquisicao: "portabilidade",
          desambiguador: "desambiguador_portabilidade",
        },
      );
    }

    if (objeto.servico === "nova-linha") {
      pushEventToDataLayer({
        event: "desambiguador_nova_linha",
        estado: objeto.estado,
      });

      setAni("");

      setCookie("telefoneCliente", "");
      fetcherOferta(
        oferta,
        null,
        objeto.operadora,
        objeto.ddd,
        objeto.numeroTemporario,
        {
          identificacao: true,
          servicoAquisicao: "nova-linha",
          desambiguador: "desambiguador_nova_linha",
        },
      );
    }
    setPreencheAni(false);

    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    // if (cookies?.processo) {
    //   setProcesso(cookies?.processo);
    // }
    if (!dadosCliente?.nomeCliente) {
      setPassoCarrinho(1);
    }
  }, []);

  useEffect(() => {
    pushEventToDataLayer({
      event: "dimensoes_personalizadas",
      userId: cookies?.processId || dadosCliente?.pid,
      ep_email: dadosCliente?.emailCliente
        ? hash256(dadosCliente?.emailCliente)
        : "",
      ep_telefone: hash256(processo?.telefoneCliente),
      fluxo: processo?.fluxo,
      desambiguador: processo?.desambiguador,
    });
    document.body.scrollTop = document.documentElement.scrollTop = 0;
  }, [passoCarrinho]);

  useEffect(() => {
    if (oferta && !validaTelefone(ani)) {
      setPreencheAni(true);
      return;
    }

    if (oferta && validaTelefone(ani)) {
      fetcherOferta(oferta, ani);
    } else {
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [oferta]);
  console.trace("passo", passoCarrinho);
  if (!planoSelecionado && loading === true) {
    return (
      <div className="w-full h-24 bg-white p-6">
        <Loading
          text=""
          show={loading}
          speed={120}
          className="w-8/12 mx-auto "
        />
      </div>
    );
  }
  if (!planoSelecionado && loading != null && !preencheAni) {
    return <Navigate to={`/?${urlParams.toString()}`} />;
  }

  if (preencheAni) {
    return (
      <div>
        {fluxo === "rentabilizacao" ? (
          <PreencheAniRentabilizacao callback={handleAniDigitado} />
        ) : (
          <PreencheAniAquisicao
            medium={medium}
            origem={origem}
            callback={handleAquisicaoPreenchida}
            novaLinha={medium === "nova-linha"}
            portabilidade={medium === "portabilidade"}
          />
        )}
      </div>
    );
  }

  return (
    <>
      {/* {!planoSelecionado && <Navigate replace to="/ofertas" />} */}
      <div className="flex flex-col bg-white">
        <PlanoSelecionado
          planoSelecionado={planoSelecionado}
          processo={processo}
          handleVoltarPasso={handleVoltarPasso}
          tagEventPayload={payloadEvent}
        />
        {passoCarrinho === 1 && <DadosCliente tagEventPayload={payloadEvent} />}
        {passoCarrinho === 2 && <DadosContato tagEventPayload={payloadEvent} />}
        {passoCarrinho === 3 && (
          <DadosPagamento tagEventPayload={payloadEvent} />
        )}
        {ultimoPassoCarrinho && <Finalizacao tagEventPayload={payloadEvent} />}
      </div>
    </>
  );
}
