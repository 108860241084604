import React from "react";

export default function Oferta({exibeSaudacao, nomeCliente, exibePlano}) {
  return (
    <div className="w-full bg-[#002198]">
      <div className=" text-white md:text-center max-w-xl mx-auto md:px-0">
        <div className="flex flex-col items-center justify-center">
          <div className="flex flex-col p-4 pb-0 gap-2 justify-between">
            <h3 className="text-xl font-normal sm:hidden">
              O sinal da <strong>TIM</strong> está com você em todas as
              possibilidades
            </h3>
            <div className="flex flex-row gap-2 items-center">
              <img
                src="/assets/banner-tim-novo.png"
                alt="Tim Controle. O único com os melhores conteúdos à sua escolha."
                className="w-auto h-40 sm:bg-cover text-white max-w-md"
              />
              <div className="flex flex-col gap-1 items-end">
                <h3 className="text-xl font-normal sm:inline-block hidden text-left">
                  O sinal da <strong>TIM</strong> está com você em todas as
                  possibilidades
                </h3>
                <h4 className="text-lg inline-block w-full text-left leading-tight">
                  Internet que não trava e que baixa vídeos mais rápido
                </h4>
                <img
                  src="/assets/logo-5g.png"
                  alt="Tim Controle. O único com os melhores conteúdos à sua escolha."
                  className="w-10 h-10 sm:bg-cover text-white max-w-md"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
