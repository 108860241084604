import React from "react";
import {useEffect} from "react";
import {useState} from "react";
import {obterData} from "../../../utils/validaData";
import {pad} from "../../../utils/textUtils";

const diasVencimento = [
  {dia: "1"},
  // { dia: "7" },
  {dia: "10"},
  {dia: "12"},
  {dia: "15"},
  {dia: "20"},
  // { dia: "25" },
];

//TODO
function proximoPagamento(dia) {
  return dia.toLocaleDateString("pt-BR", {
    year: "numeric",
    month: "numeric",
    day: "numeric",
  });
}

function Fatura({callbackSelecao, tipoFatura, diaVencimento, tipoConta}) {
  const [diasDisponiveis, setDiasDisponiveis] = useState([]);
  const [diaSelecionado, setDiaSelecionado] = useState(null);
  const [melhorDia, setMelhorDia] = useState(null);

  useEffect(() => {
    setDiasDisponiveis(diasVencimento);
    if (diaVencimento && tipoConta === tipoFatura.tipoConta) {
      setDiaSelecionado({dia: diaVencimento});
    }
  }, []);

  const hanldeSalvar = () => {
    callbackSelecao &&
      callbackSelecao(diaSelecionado.dia, melhorDia?.getDate());
  };
  const handleSelecaoDia = (d) => {
    if (diaSelecionado?.dia === d.dia) {
      setDiaSelecionado(null);
      return;
    }
    setDiaSelecionado(d);
  };
  useEffect(() => {
    const melhorVencimento = obterData(new Date());
    setMelhorDia(melhorVencimento || null);
  }, []);

  return (
    <div className="">
      <h4 className="mb-1 text-sm font-bold text-secondary-tim">
        Escolha o vencimento
      </h4>
      <div>
        {melhorDia && !diaSelecionado?.dia && (
          <span className="inline-block py-2 z-0 relative text-sm font-medium text-secondary-tim">
            Selecionando o dia{" "}
            <span className="relative px-1">
              {pad(melhorDia.getDate(), 2)}
              <span className="absolute bottom-0.5 -z-10 left-0 h-1.5 w-full bg-azul-tim-light"></span>
            </span>
            , você só paga em{" "}
            <em className="relative px-1">
              {proximoPagamento(melhorDia)}
              <span className="absolute bottom-0.5 -z-10 left-0 h-1.5 w-full bg-azul-tim-light"></span>
            </em>
          </span>
        )}
        <div className="flex flex-row gap-2 py-2">
          {diasDisponiveis.map((d, i) => (
            <span
              key={"selecao_dia_" + d + "___" + i}
              onClick={() => handleSelecaoDia(d)}
              className={`px-2 py-1.5 w-10 inline-block text-center cursor-pointer font-bold text-sm rounded-lg ${
                diaSelecionado?.dia === d.dia
                  ? "bg-azul-tim-light text-secondary-tim"
                  : "bg-secondary-tim text-white"
              }`}
            >
              {d.dia}
            </span>
          ))}
        </div>
      </div>
      <button
        disabled={!diaSelecionado}
        onClick={hanldeSalvar}
        className="mt-2 text-white btn bg-secondary-tim disabled:bg-secondary-tim/70"
      >
        Selecionar
      </button>
    </div>
  );
}

export default Fatura;
